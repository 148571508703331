import styled from "styled-components"


export const BannerWrapper = styled.div`
  padding: 30vh 0 8vh 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40vh;
  height: 100%;
  width: 100%;
  background-color: transparent;
  background-image: linear-gradient(180deg,#777 0%,#333 100%);
  background-repeat: no-repeat;
  background-size: cover;


  h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
    text-transform: none;
    text-align: left;
    font-family: "Montserrat", Sans-serif;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 40px;
    }
  }

  .vertical_center{
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
 
`

