import React from "react";
import { useState } from "react";
import { BannerWrapper } from "./PageBanner.styles"
import {Container, Col, Row} from "react-bootstrap";

const PageBanner = (props) => {
    return(
        <BannerWrapper>
                <Container>
                        <h1>
                            {props.heading}
                        </h1>
                </Container>                
        </BannerWrapper>
    )
}
export default PageBanner;